<template>
    <form ref="form" v-on:submit.prevent="submit" v-bind:action="'/courses/' + courseId" method="post">
        <input type="hidden" name="_token" v-bind:value="csrfToken"/>

        <div class="form-group" v-bind:class="{ 'has-error': 'personName' in errors }">
            <label for="personName">{{ $t('components.booking_form.person_name') }}</label>
            <input type="text" v-model="personName" class="form-control" id="personName" name="participant_name" />
            <span v-if="{ 'has-error': 'personName' in errors }" class="help-block">{{ errors['personName'] }}</span>
        </div>

        <div class="form-group date" v-bind:class="{ 'has-error': 'personBirthday' in errors }">
            <label for="personBirthday">{{ $t('components.booking_form.person_birthday') }}</label>
            <input type="text" class="form-control" id="personBirthday" />
            <span v-if="{ 'has-error': 'personBirthday' in errors }" class="help-block">{{ errors['personBirthday'] }}</span>
        </div>

        <input id="personBirthdayHidden" type="hidden" name="participant_birthday" value=""/>

        <div class="form-group">
            <label for="medicalNotes">{{ $t('components.booking_form.medical_notes') }}</label>
            <input type="text" class="form-control" id="medicalNotes" name="participant_medical_notes" />
        </div>

        <div class="checkbox" v-bind:class="{ 'has-error': 'terms' in errors }">
            <label for="terms">
                <input v-model="terms" type="checkbox" id="terms" name="terms" /> {{ $t('components.booking_form.terms') }} <a href="https://baby-kind-hanne.de/datenschutz/">{{ $t('components.booking_form.terms_link') }}</a>
            </label>
            <span v-if="'terms' in errors" class="help-block">{{ errors['terms'] }}</span>
        </div>

        <div class="text-center">
            <button type="submit" class="btn btn-success text-center" v-bind:disabled="!enabled">{{ $t('components.booking_form.book_now') }}</button>
        </div>
    </form>
</template>

<script>
    export default {
        name: "BookingForm",
        props: {
            courseId: {
                type: Number,
                required: true
            }
        },
        data: function() {
            return {
                csrfToken: "",
                personName: "",
                terms: false,
                errors: {},
                enabled: true
            }
        },
        mounted: function() {
            this.csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
        },
        methods: {
            validate: function() {
                this.errors = {};
                if (!this.personName) {
                    this.errors['personName'] = this.$t('validation.is_required');
                }

                if (!this.terms) {
                    this.errors['terms'] = this.$t('validation.terms');
                }

                let personBirthday = window.jQuery('#personBirthday').data('DateTimePicker').date();
                if (!personBirthday || !personBirthday.toISOString()) {
                    this.errors['personBirthday'] = this.$t('validation.is_required');
                }
            },
            submit: function() {
                this.validate();
                if (this.enabled && Object.keys(this.errors).length === 0) {
                    let selectedDate = window.jQuery('#personBirthday').data('DateTimePicker').date().toISOString();
                    window.jQuery('#personBirthdayHidden').val(selectedDate);
                    this.$refs.form.submit();
                    this.enabled = false;
                }
            }
        }
    }
</script>

<style scoped>

</style>
