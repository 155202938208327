<template>
    <div>
        <div class="row" v-if="message">
            <div class="col-sm-12">
                <div class="alert alert-danger">
                    <h4><i class="icon fa fa-warning"></i> {{ $t('components.error_display.heading') }}</h4>
                    {{ message }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ErrorDisplay",
        data: function () {
            return {
                message: null,
            }
        },
        methods: {
            NOP: function(x) {},

            reset: function () {
                this.message = null;
            },

            handleRequest: function (request, callback) {
                this.NOP(request);
                request
                    .then(response => {
                        let result = response.data;
                        if (result !== null && result.status !== null) {
                            if (result.status === "success") {
                                this.message = null;
                                callback(result);
                            } else {
                                this.message = this.$t(result.translationKey);
                            }
                        } else {
                            this.message = this.$t('api.errors.server_error');
                        }
                    })
                    .catch(error => {
                        if (error.response) {
                            let result = error.response.data;
                            if (result && result.translationKey) {
                                this.message = this.$t(result.translationKey);
                            } else {
                                this.message = this.$t('api.errors.client_error');
                            }
                        }
                    })
            }
        }
    }
</script>
