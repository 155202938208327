import TimeAgo from 'javascript-time-ago';
import Vue from 'vue';

/**
 * Settings up localization.
 */
// Time Ago
import TimeAgoEn from 'javascript-time-ago/locale/en';
import TimeAgoDe from 'javascript-time-ago/locale/de';

TimeAgo.addLocale(TimeAgoEn);
TimeAgo.addLocale(TimeAgoDe);

Vue.mixin({
    beforeCreate() {
        const options = this.$options;
        if (options.timeAgo)
            this.$timeAgo = options.timeAgo;
        else if (options.parent && options.parent.$timeAgo)
            this.$timeAgo = options.parent.$timeAgo;
    }
});

export default new TimeAgo(document.documentElement.lang);
