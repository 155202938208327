<template>
    <div class="container">
        <div class="row">
            <error-display ref="errorHandler"></error-display>

<!--            <form>-->
<!--                <div class="form-group">-->
<!--                    <label for="age_category">{{ $t('components.class_table.age_category') }}</label>-->
<!--                    <select v-on:change="loadCourses" v-model="ageCategory" id="age_category" class="form-control">-->
<!--                        <option v-bind:value="null" selected>{{ $t('components.class_table.any') }}</option>-->
<!--                        <option v-for="item in enums.ageCategories" v-bind:value="item.id">{{ translateEnum(item) }}</option>-->
<!--                    </select>-->
<!--                </div>-->
                <!--
                <div class="form-group">
                    <label for="class_category">{{ $t('components.class_table.class_category') }}</label>
                    <select v-on:change="loadCourses" v-model="classCategory" id="class_category" class="form-control">
                        <option v-bind:value="null" selected>{{ $t('components.class_table.any') }}</option>
                        <option v-for="item in enums.classCategories" v-bind:value="item.id">{{ translateEnum(item) }}</option>
                    </select>
                </div>
                -->
<!--                <div class="form-group">-->
<!--                    <label for="swimming_pool">{{ $t('components.class_table.swimming_pool') }}</label>-->
<!--                    <select v-on:change="loadCourses" v-model="swimmingPool" id="swimming_pool" class="form-control">-->
<!--                        <option v-bind:value="null" selected>{{ $t('components.class_table.any') }}</option>-->
<!--                        <option v-for="item in enums.swimmingPools" v-bind:value="item.id">{{ translateEnum(item) }}</option>-->
<!--                    </select>-->
<!--                </div>-->
<!--                <div class="form-group">-->
<!--                    <label for="weekday">{{ $t('components.class_table.weekday') }}</label>-->
<!--                    <select v-on:change="loadCourses" v-model="weekday" id="weekday" class="form-control">-->
<!--                        <option v-bind:value="null" selected>{{ $t('components.class_table.any') }}</option>-->
<!--                        <option v-for="id in [0, 1, 2, 3, 4, 5, 6]" v-bind:value="id">{{ $t('components.class_table.day_' + id) }}</option>-->
<!--                    </select>-->
<!--                </div>-->
<!--            </form>-->
        </div>
        <div class="row">
            {{ $t('components.class_table.courses_displayed', [first, last, total]) }}

            <div class="pull-right">
                <div class="btn-group">
                    <button v-on:click="previousPage" type="button" class="btn btn-default btn-sm">«</button>
                    <button v-on:click="nextPage" type="button" class="btn btn-default btn-sm">»</button>
                </div>
            </div>
        </div>

        <span class="separator"></span>

        <div class="row">
            <div class="col-sm-12">
                <div class="text-center">
                    <div class="btn-group">
                        <button v-on:click="previousPage" type="button" class="btn btn-default btn-sm">«</button>
                        <button v-on:click="nextPage" type="button" class="btn btn-default btn-sm">»</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th>{{ $t('components.class_table.age_category') }}</th>
                        <th>{{ $t('components.class_table.class_category') }}</th>
                        <th>{{ $t('components.class_table.first_datetime') }}</th>
                        <th>{{ $t('components.class_table.swimming_pool') }}</th>
                        <th>{{ $t('components.class_table.no_datetimes') }}</th>
                        <th>{{ $t('components.class_table.price') }}</th>
                        <th>{{ $t('components.class_table.book') }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in classes">
                        <td>{{ translateEnum(resolveEnum(enums.ageCategories, item.age_category_id)) }}</td>
                        <td>{{ translateEnum(resolveEnum(enums.classCategories, item.class_category_id)) }}</td>
                        <td>{{ $moment(item.first_date_time).local() | moment('dddd') }}, {{ $moment(item.first_date_time).local() | moment('L') }} {{ $moment(item.first_date_time).local() | moment('LT') }}</td>
                        <td>{{ translateEnum(resolveEnum(enums.swimmingPools, item.swimming_pool_id)) }}</td>
                        <td>{{ item.no_date_times }}</td>
                        <td>
                            {{ item.class_fee }} €<br/>
                            <span v-if="item.discounted" class="label label-danger">{{ $t('components.class_table.discounted') }}</span>
                        </td>
                        <td>
                            <a v-bind:href="'/courses/' + item.id">
                                <button class="btn btn-success">{{ $t('components.class_table.book_now') }}</button>
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="row">
            <div class="col-sm-12">
                <div class="text-center">
                    <div class="btn-group">
                        <button v-on:click="previousPage" type="button" class="btn btn-default btn-sm">«</button>
                        <button v-on:click="nextPage" type="button" class="btn btn-default btn-sm">»</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ErrorDisplay from "./ErrorDisplay";

    export default {
        name: "ClassTable",
        data: function () {
            return {
                page: 0,
                pageSize: 50,

                enums: {
                    ageCategories: [],
                    classCategories: [],
                    swimmingPools: [],
                },

                total: 0,
                classes: [],

                ageCategory: null,
                classCategory: null,
                swimmingPool: null,
                weekday: null,
            }
        },

        computed: {
            first: function () {
                if (this.total === 0) return 0;
                return this.page * this.pageSize + 1;
            },
            last: function () {
                if (this.total === 0) return 0;
                return Math.min((this.page + 1) * this.pageSize, this.total);
            }
        },

        mounted: function () {
            let self = this;
            this.$refs.errorHandler.reset();
            let request = axios.get('/api/enums');
            this.$refs.errorHandler.handleRequest(request, function (result) {
                self.enums.ageCategories = result.data.age_categories;
                self.enums.classCategories = result.data.class_categories;
                self.enums.swimmingPools = result.data.swimming_pools;
                self.loadCourses();
            });
        },

        methods: {
            loadCourses: function () {
                let self = this;
                this.$refs.errorHandler.reset();
                let query = '/api/swimming-classes?page=' + this.page + "&page_size=" + this.pageSize;

                if (this.ageCategory !== null) {
                    query += "&age_category=" + this.ageCategory;
                }
                if (this.classCategory !== null) {
                    query += "&class_category=" + this.classCategory;
                }
                if (this.swimmingPool !== null) {
                    query += "&swimming_pool=" + this.swimmingPool;
                }
                if (this.weekday !== null) {
                    query += "&weekday=" + this.weekday;
                }

                let request = axios.get(query);
                this.$refs.errorHandler.handleRequest(request, function (result) {
                    self.classes = result.data.classes;
                    self.total = result.data.total;
                    self.page = result.data.page;
                    self.pageSize = result.data.page_size;
                });
            },

            previousPage: function () {
                if (this.page > 0) {
                    this.page--;
                    this.loadCourses();
                }
            },
            nextPage: function () {
                if (this.last < this.total) {
                    this.page++;
                    this.loadCourses();
                }
            },

            translateEnum: function (item) {
                if (!item) {
                    return "";
                }

                let lang = this.$i18n.locale;
                if (lang && item.i18n[lang]) {
                    return item.i18n[lang];
                }
                return item.name;
            },
            resolveEnum: function (array, id) {
                for (let i = 0; i < array.length; ++i) {
                    if (array[i].id === id) {
                        return array[i];
                    }
                }
                return null;
            }
        },

        components: {
            ErrorDisplay
        }
    }
</script>
