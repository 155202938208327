export default {
    test: "TEST",
    template: {
        elements: {
            labels: {},
            navbar: {},
            input: {
                datemask: 'dd.mm.yyyy'
            }
        },
        settings: {
            dateformat: "DD.MM.YYYY",
            timeformat: "HH:mm:ss"
        }
    },
    components: {
        error_display: {
            heading: "Fehler!",
        },
        registration_form: {
            first_name: "Vorname",
            last_name: "Nachname",
            email_address: "E-Mail-Adresse",
            password: "Passwort",
            password_confirmation: "Passwort (Bestätigung)",
            city: "Stadt",
            zipcode: "Postleitzahl",
            street: "Straße",
            housenumber: "Hausnummer",
            phonenumber: "Telefonnummer",

            submit: "Abschicken",

            password_guidelines: "Passwörter müssen zwischen {0} und {1} Zeichen lang sein und mindestens einen Großbuchstaben, einen Kleinbuchstaben, eine Ziffer und ein Sonderzeichen enthalten."
        },
        reset_password_form: {
            password: "Neues Passwort",
            password_confirmation: "Neues Passwort (Bestätigung)",
            submit: "Passwort zurücksetzen"
        },
        signin_form: {
            email: "E-Mail-Adresse",
            password: "Passwort",
            submit: "Einloggen"
        },
        class_explorer: {
            age_category: "Altersstufe",
            class_category: "Kursstufe",
            swimming_pool: "Schwimmbad",

            any: "Beliebig",
            courses_displayed: "Zeige Kurse {0} - {1} von {2}"
        },
        class_table: {
            age_category: "Altersstufe",
            class_category: "Kursstufe",
            swimming_pool: "Schwimmbad",
            first_datetime: "Erster Kurstermin",
            price: "Preis",
            no_datetimes: "Kurseinheiten",
            pool_fee_inclusive: "inkl. Eintritt",
            pool_fee_exclusive: "zzgl. Eintritt",
            discounted: "Rabattiert!",
            book: "Buchen",
            book_now: "Jetzt buchen!",
            any: "Beliebig",
            courses_displayed: "Zeige Kurse {0} - {1} von {2}",
            weekday: "Wochentag",
            day_0: "Montag",
            day_1: "Dienstag",
            day_2: "Mittwoch",
            day_3: "Donnerstag",
            day_4: "Freitag",
            day_5: "Samstag",
            day_6: "Sonntag",
        },
        course_details: {
            age_category: "Altersstufe",
            class_category: "Kursstufe",
            swimming_pool: "Schwimmbad",
            datetimes: "Datum und Uhrzeit",
            class_fee: "Kursgebühr",
            pool_fee_inclusive: "(inkl. Eintritt)",
            pool_fee_exclusive: "(zzgl. Eintritt)",
        },
        booking_form: {
            person_name: "Name des Kursteilnehmers",
            person_birthday: "Geburtstag des Kursteilnehmers",
            medical_notes: "Zusätzliche, gesundheitliche Hinweise (optional)",
            terms: "Ich erkläre mich mit den AGB einverstanden:",
            terms_link: "nachlesen",
            book_now: "Jetzt buchen!",
        },
        booking_list: {
            age_category: "Altersstufe",
            person_name: "Teilnehmer",
            status: "Status",
            invoice: {
                regular: "Bestätigung herunterladen",
                cancellation: "Stornobestätigung herunterladen",
            },
            awaits_payment: "Gebucht",
            paid: "Gebucht",
            canceled: "Storniert",
            actions: "Aktionen",
            course: "Kursinfo ansehen",
            no_bookings: "Keine Buchungen vorhanden."
        }
    },
    api: {
        errors: {
            client_error: "Verbindung zum Server fehlgeschlagen.",
            server_error: "Server konnte Anfrage nicht verarbeiten.",
            unauthenticated: "Sie sind nicht authentifiziert.",
            unauthorized: "Sie sind für die geforderte Aktion nicht authorisiert.",
            invalid_arguments: "Die übergebenen Argumente sind ungültig.",
            not_found: "Die angegebene Ressource konnte nicht gefunden werden.",
            not_allowed: "Die geforderte Aktion ist nicht erlaubt.",
        },
    },
    validation: {
        is_required: "Feld ist obligatorisch",
        terms: "AGB müssen akzeptiert werden",
        invalid_email: "Ungültige E-Mail-Adresse",
        email_already_in_use: "E-Mail-Adresse wird bereits verwendet",
        password_mismatch: "Passwörter stimmen nicht überein",
        password_too_short: "Passwort zu kurz; muss mindestens {0} Zeichen lang sein",
        password_too_long: "Passwort zu lang; darf maximal {1} Zeichen lang sein",
        password_no_uppercase: "Passwort muss mindestens einen Großbuchstaben enthalten",
        password_no_lowercase: "Passwort muss mindestens einen Kleinbuchstaben enthalten",
        password_no_special_character: "Passwort muss mindestens ein Sonderzeichen enthalten",
        password_no_digit: "Passwort muss mindestens eine Ziffer enthalten",
        password_invalid: "Passwort erfüllt Anforderungen nicht",
        server_rejected: "Abgelehnt vom Server",
    },
    errors: {
        server_error: "Ein interner Fehler ist aufgetreten. Bitte versuchen Sie es später erneut."
    }
}
